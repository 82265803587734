import {
    USERS_FETCHED
  } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {

    case USERS_FETCHED:
      return action.payload;

    default:
      return state;

  }
}