import {
    PUBLIC_LOGGED
  } from "../actions/types";

export default function(state = "empty", action) {
  switch (action.type) {

    case PUBLIC_LOGGED:
      return action.payload;

    default:
      return state;

  }
}