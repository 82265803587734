import "./css/style.css";
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Sommaire from "./components/sommaire";
import Legal from "./components/legal";
import CreateUser from "./components/createUser";
import UserList from './components/userList'
import Prez from './components/prez'

export default class App extends Component {

  render() {

    return (
      <BrowserRouter>
        <div className="container-fluid"> 
          <nav className="navbar navbar-expand-lg navbar-light">
            <a href="https://verbatim-app.com" alt="home">
              <img className="navbar-brand nav-logo" src="https://firebasestorage.googleapis.com/v0/b/bibemus-bankin.appspot.com/o/assets%2FlogoHeader_Verbatim.png?alt=media&token=516ee079-d1d2-4f38-aabb-b51580ef3537"
              alt="logo-verbatim" />
            </a>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <a className="nav-link" href="#connect">Se connecter</a>
                </li>
            </ul>
          </nav>
          <br />
          <Switch>
            <Route exact path="/users" component={() => <UserList />} />
            <Route exact path="/new" component={() => <CreateUser />} />
            <Route exact path="/presentation" component={() => <Prez />} />
            <Route exact path="/legal" component={() => <Legal />} />
            <Route exact path="/" component={() => <Sommaire />} /> 
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}
