import React, { Component } from "react";
import { connect } from "react-redux";
import {fetchUsers} from '../actions'
import axios from 'axios'

class UserList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            users: []
        }
    }

    componentDidMount() {
        this.getData()
        
    }

    generateList() {
        let {users} = this.state;

        const map = users.map((user) => {
            return (<div>{user.email}</div>)
        })

        return map
    }

    getData = () => {
        axios.get('https://bibemus-server.herokuapp.com/listUsers')
        .then((response) => {
            this.setState({users: response.data})
        })
    }

    render() {

        let {users} = this.state;

        if (typeof users[0] !== "undefined") {

            return (
            
                
                <div className="card">
                <div className="card-body">
                <img src="https://i.imgur.com/PyZxjzY.png" className="img-logo" alt="logo"/>
                    <p><span className="merci">Liste des utilisateurs en base</span></p>
                    {this.generateList()}</div>
                </div>
        
             )
        } else {
            return <div>Chargement en cours...</div>
        }

        
    }
}
  
function mapStateToProps(state) {
	return { users: state.users };
}
  
export default connect(mapStateToProps, {fetchUsers})(UserList)