import { USERS_FETCHED, PUBLIC_LOGGED } from "./types"
import axios from 'axios'

export function fetchUsers(response) {
    return (dispatch => {
        dispatch({
            type: USERS_FETCHED,
            payload: response
          })
    })
};

export function publicLogin(pass) {
    return (dispatch => {
        axios.post('https://bibemus-server.herokuapp.com/publicLogin?password=' + pass)
        .then((response) => {

            console.log(response)
            dispatch({
                type: PUBLIC_LOGGED,
                payload: response.data
              })
        })
    })
}
