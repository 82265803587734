import React, { Component } from "react";
import { Field, reduxForm, reset } from "redux-form";
import axios from 'axios'

class CreateUser extends Component {

    renderTitleField = field => (
        <div className="form-group">
            <label className="login-label">{field.label}</label>
            <input
            type={field.formType}
            className="form-control"
            placeholder={field.placeholder}
            {...field.input}
            />
            {field.meta.touched ? field.meta.error : ""}
        </div>
    );

    onSubmit(values) {

        let {email, password, firstName, lastName} = values

        axios.post('https://bibemus-server.herokuapp.com/createUser?email=' + email + '&password=' + password + '&firstName=' + firstName + '&lastName=' + lastName)
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    render() {

        const { handleSubmit } = this.props;

        return (
            <div className="App">
                <div className="card">
                <div className="card-body">
                <img src="https://i.imgur.com/PyZxjzY.png" className="img-logo" alt="logo"/>
                    <p><span className="merci">Formulaire de création de compte</span></p>
                      <form onSubmit={handleSubmit(this.onSubmit.bind(this))} >
                        <Field
                            label="Adresse mail"
                            placeholder="Merci d'entrer une adresse valide"
                            name="email"
                            formType="email"
                            component={this.renderTitleField}
                        />
                        <Field
                            label="Mot de passe"
                            placeholder="Définissez un mot de passe"
                            name="password"
                            formType="password"
                            component={this.renderTitleField}
                        />
                        <Field
                            label="Prénom"
                            placeholder=""
                            name="firstName"
                            formType="text"
                            component={this.renderTitleField}
                        />
                        <Field
                            label="Nom de famille"
                            placeholder=""
                            name="lastName"
                            formType="text"
                            component={this.renderTitleField}
                        />
                        <button type="submit" className="btn btn-primary" >
                            Créer le compte
                        </button>
                      </form>
                      <br />
                </div>
            </div>
            </div>
        )
    }
}

const validate = values => {
    let errors = {};
  
    if (!values.email)
      errors.email = (
        <div className="login-errorMessage">
          Please input a valid mail address
        </div>
      );
    if (!values.password)
      errors.password = (
        <div className="login-errorMessage">Password is required</div>
      );
  
    return errors;
  };


const afterSubmit = (result, dispatch) =>
  dispatch(reset('logInForm'));
  
export default reduxForm({
    form: "logInForm",
    validate: validate,
    onSubmitSuccess: afterSubmit
  })(CreateUser)