import React, { Component } from "react";
import { connect } from "react-redux";
import {publicLogin} from '../actions'

class PublicLogin extends Component {

    constructor(props){
        super(props);

        this.state = {password: ''}
    }

    sendForm(e) {
        e.preventDefault()

        this.props.publicLogin(this.state.password)

        this.setState({password: ''})
    }
  
    render() {

        return (
        
            
            <div className="card">
            <div className="card-body">
                <p><span className="merci">Merci d'entrer le mot de passe qui vous a été communiqué</span></p>
                <div className="public-pass">
                    <form className="form-inline" onSubmit={(e) => this.sendForm(e)}>
                        <input className="form-control flex-nowrap" value={this.state.password} type="password" onChange={(e) => this.setState({password: e.target.value})} />
                        <button className="btn btn-outline-success">Connect</button>
                    </form>
                </div>
            </div>
            </div>
    
        )
    }
}  

export default connect(null, {publicLogin})(PublicLogin)