import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import UsersReducer from './usersReducer'
import PublicLoginReducer from './publicLoginReducer'

const rootReducer = combineReducers({
    form: formReducer,
    users: UsersReducer,
    publicLogin: PublicLoginReducer
});

export default rootReducer;