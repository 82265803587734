import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Document, Page } from 'react-pdf';
import PublicLogin from './publicLogin'
import { publicLogin } from './../actions/'

class Prez extends Component {

    constructor(props) {
        super(props);
        this.state = { numPages: null, pageNumber: 1 };
      }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    getWidthForPDF = () => {
        let windowWidth = window.innerWidth
        return 0.95 * windowWidth
    }

    
    
    render() {

    const { pageNumber, numPages } = this.state;

    if (this.props.location.search.includes('complete')) {
        if (this.props.publicLog !== "Ok") {
            return (
                <div className="visio">
                    <div className="visio-2">
                        <PublicLogin /> 
                    </div>
                </div>
                );
        } else {
            return (
                <div className="visio">
                    <div className="visio-2">
                    <Document
                            file='https://firebasestorage.googleapis.com/v0/b/bibemus-bankin.appspot.com/o/assets%2FVERBATIM%20-%20pitch%20oddo%202019%2003%2014.pdf?alt=media&token=a4f89d84-cdce-4a82-b721-5819bed43286'
                            onLoadSuccess={this.onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} width={this.getWidthForPDF()} scale={0.8}/>
                        </Document>
                    </div>
                    <p>Page {pageNumber} of {numPages}</p>
                    <div className="buttons">
                        <button onClick={() => this.setState({pageNumber: pageNumber - 1})} type="button" className="btn btn-outline-success">Previous</button>
                        <button onClick={() => this.setState({pageNumber: pageNumber + 1})} type="button" className="btn btn-outline-success">Next</button>
                    </div>
                </div>
                );
        } 
    } else {
        return (
            <div>
                <div className="visio">
                    <div className="visio-2">
                        <Document
                            file='https://firebasestorage.googleapis.com/v0/b/bibemus-bankin.appspot.com/o/assets%2FVERBATIM%20-%20pitch%202019%2002%2020%20sans-compressed.pdf?alt=media&token=891dc1dc-88cf-448d-8f56-87a0d33b3da0'
                            onLoadSuccess={this.onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} width={this.getWidthForPDF()} scale={0.8}/>
                        </Document>
                    </div>
                    <p>Page {pageNumber} of {numPages}</p>
                    <div className="buttons">
                        <button onClick={() => this.setState({pageNumber: pageNumber - 1})} type="button" className="btn btn-outline-success">Previous</button>
                        <button onClick={() => this.setState({pageNumber: pageNumber + 1})} type="button" className="btn btn-outline-success">Next</button>
                    </div>
                </div> 
                
            </div>
            );
    }

    
    }
}      
  
function mapStateToProps(state) {
	return { publicLog: state.publicLogin };
}
  
export default withRouter(connect(mapStateToProps, {publicLogin})(Prez))