import React, { Component } from "react";
import { Link } from 'react-router-dom'

export default class Sommaire extends Component {

    render() {
        return (
            <div className="App">
                <div className="card">
                <div className="card-body">
                <div className="download">
                    <img src="https://i.imgur.com/PyZxjzY.png" className="img-logo" alt="logo"/>
                    <p><span className="merci">Merci de participer au développement de Verbatim.</span></p>
                    <p>Vous pourrez bientôt télécharger l'application sur l'App Store iOS.</p>
                    <span className="subtitle">iOS 10 requis, une connexion internet est requise pour envoyer des Verbatim au service cloud.</span>
                    <br /><br />
                    <a href="mailto:arthur@bibemus-consulting.com"><button type="button" className="btn btn-vert">Contacter le support</button></a>
                    {/* <a href="itms-services://?action=download-manifest&url=https://bibemus-server.herokuapp.com/app/manifest.plist"><button type="button" className="btn btn-vert">Télécharger l'app</button></a> */}
                    
                    <br />
                    <Link  to="/legal"><div className="legal">Mentions légales</div></Link>
                </div>
                </div>
            </div>
            </div>
        )
    }
}